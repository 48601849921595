@import url("https://fonts.googleapis.com/css?family=Arapey|Cantarell|Comfortaa|Khand|Russo+One|Ubuntu");

.App {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;

  .floor-plan {
    width: fit-content;
    position: absolute;
    z-index: 9;
    display: flex;

    &.visible {
      width: 100%;
      height: 100vh;
      background-color: #000000d4;

      .floor-plan-icon {
        z-index: 9;
        border: 1px solid black;
        border-left-width: 0px;

        img {
          padding: 5px;
        }
      }

      .floor-plan-container {
        margin: 5%;
        position: relative;
        height: fit-content;

        @media screen and (orientation: portrait) {  
          width: 100%;
          margin: unset;
        }
        .rotate-device {
          width: 100%;
          height: 100vh;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: unset;
          flex-direction: column;
          img {
            height: 128px;
          }
        }

        .dot {
          width: 26px;
          height: 26px;
          position: absolute;
          display: block;
          border-radius: 50%;
          cursor: pointer;
          img {
            height: 26px;
            width: 26px;

            &.pulse {
              animation: pulse 1s infinite ease-in-out alternate;
            }

            @keyframes pulse {
              from {
                transform: scale(0.8);
              }
              to {
                transform: scale(1.2);
              }
            }
          }
        }

        .floor-plan-image {
          width: 100%;
          height: fit-content;
          margin: 0 auto;
          position: relative;
          border-radius: 4px;
          @media screen and (orientation: portrait) {
            transform: rotate(90deg) translateY(-100%);
            transform-origin: top left;
            height: 100vw;
            width: 100vh;
            margin: unset;
          }
        }
      }

      .floor-plan-first {
        top: 135px;
      }

      .floor-plan-second {
        top: 190px;
      }

      .floor-plan-first,
      .floor-plan-second {
        position: absolute;
        &:hover {
          background-color: #ffd152;
        }
        &.active {
          background-color: #ffd152;
        }
      }
    }

    .floor-plan-icon {
      width: fit-content;
      background-color: white;
      height: auto;
      position: absolute;
      left: 0;
      top: 70px;
      display: block;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      cursor: pointer;

      img {
        padding: 15px;
        padding-bottom: 0;
        height: 40px;
      }

      span {
        width: 100%;
        text-align: center;
        display: block;
        padding-left: 2px;
      }
    }
  }

  .vr-main {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .additional-content {
    width: 0;
    height: 0;
    &.active {
      z-index: 1;
      width: 100%;
      height: 100%;
      position: fixed;
    }

    .vr-right-menu {
      position: fixed;
      right: 15px;
      top: 15px;
      z-index: 9;
      img {
        height: 35px;
      }
      @media screen and (max-width: 768px) {
        img {
          height: 20px;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .logo-img {
    position: fixed;
    width: 140px;
    z-index: 9;
    top: 20px;
    left: 20px;
    @media screen and (max-width: 768px) {
      top: 7px;
      left: 7px;
    }
  }

  /*tooltip */
  .tooltip {
    visibility: hidden;
    z-index: 1;
    opacity: 0.4;
    width: fit-content;
    white-space: nowrap;
    padding: 0px 20px;
    background: #333;
    color: white;
    position: absolute;
    top: -140%;
    left: -75%;
    border-radius: 9px;
    font: 16px;
    transform: translateY(9px);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
  }

  /* tooltip  after*/
  .tooltip::after {
    content: " ";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12.5px 0 12.5px;
    border-color: #333 transparent transparent transparent;
    position: absolute;
  }

  .con-tooltip:hover .tooltip {
    visibility: visible;
    transform: translateY(-25px);
    opacity: 1;
    transition: 0.1s linear;
  }
}
